import { link, tokenLink, urlADM } from "../../services/variables";

const jsonButtons = [
  { name: `Inicio`, link: `/`, img: `Inicio` },
  {
    name: `Coronavírus (Covid-19)`,
    link: `${link}/portaltm/covid19/index.xhtml?token=${tokenLink}`,
    img: `Coronavírus (Covid-19)`,
  },
  {
    name: `Fale Conosco`,
    link: `/detalhe/fale-conosco-atendimento`,
    img: `telephone`,
  },
  { name: `Notícias`, link: `/noticias`, img: `Notícias` },
  {
    name: `e-SIC`,
    link: `${urlADM}/e-sic?token=${tokenLink}`,
    img: `e-SIC`,
  },

  //,{name: `Regulamentação LAI`, link: `${link}/esic/index.xhtml?token=${tokenLink}`, img: `e-SIC`}
  //,{name: `Julgamento das Contas`, link: `/legislativo-municipal/julgamento-das-contas-do-governador/prefeito`, img: `e-SIC`}
  //,{name: `Inspeções e Auditorias`, link: `/legislativo-municipal/resultado-inspecoes-prestacoes-e-tomadas-de-contas`, img: `e-SIC`}
  {
    name: `Perguntas e Respostas`,
    link: `${urlADM}/e-sic/perguntas-e-respostas?token=${tokenLink}`,
    img: `Perguntas_e_Respostas`,
  },
];

export default jsonButtons;

// (e-SIC) ,{name: `Certidões`, link: ``, img: `Certidões`}
// ,{name: `Diário Oficial`, link: `${link}/portal/diario/ultimasPublicacoes.xhtml?token=${tokenLink}`, img: `Diário Oficial`}
